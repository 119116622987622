.-icon {
  background-size: cover;
}

.-icon-project {
  width: 50px;
  min-width: 50px;
  height: 50px;
  background-image: url('/assets/icn_project.svg');
}

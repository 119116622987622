@use 'src/styles/_icn.scss';

.wrap {
  width: 100%;
  padding: 0.5rem;
  background-color: #fff;
  border-radius: 0.5rem;
}

.name {
  color: #3f3f46;
}

.due_date {
  color: #3f3f46;
  text-align: right;
}
